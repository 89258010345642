
































































































































































































































































































































































































































































































































































.publisherContainer {
  &:hover {
    .publisherControls {
      opacity: 1;
    }
  }

  .publisherControls {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transition: opacity 0.5s;
    z-index: 100;
    transform: translateX(-50%);

    &.mobile {
      left: 16px;
      transform: initial;
    }
  }
}
