



















































































































































































































































































































































































































































.public-session-video {
  .mobile {
    padding: 0px;

    .publisher-container {
      margin-top: 0px;
      padding: 0px;
    }

    .row:nth-child(2) {
      position: absolute;
      width: 100%;
      top: 10px;
      left: 20px;
      margin-top: 0px;

      > [class*='col'] {
        padding: 0px;

        .subscriber-container {
          margin-top: 0px;
        }
      }
    }
  }
  .publisher-container {
    margin-top: 10px;
    margin-right: 100px;
    padding: 5px;
    transform: scale(0);
    transition: transform 0.5s;

    &.creating,
    &.created {
      transform: scale(1);
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .subscriber-container {
    margin-top: 10px;
    margin-right: 100px;
    padding: 5px;
    transform: scale(0);
    transition: transform 0.5s;

    &.creating,
    &.created {
      transform: scale(1);
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
